import React, { useState } from 'react'
import logo from "../images/logo.png"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import userAction from "../redux/users/action";
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import copy from 'copy-to-clipboard';
import broadCastIco from '../images/broadCastIco.png';
import { useMediaQuery } from 'react-responsive';

export const Navbar = ({show,setShow}) => {
  const userStore = useSelector((state) => state.userReducer);
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [courseData, setCourseData]=useState([])
  const [copyText, setCopyText]=useState("Copy to clipboard");
  const [activeBtn, setActiveBtn]=useState("monthly");
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showYearly, setShowYearly]=useState(false)
  const handleCloseYearly = () => setShowYearly(false);
  const handleShowYearly = () => setShowYearly(true);

  const [showBroadcast, setShowBroadcast]=useState(false)
  const handleCloseBroadcast = () => setShowBroadcast(false);
  const handleShowBroadcast = () => setShowBroadcast(true);

  const isTab = useMediaQuery({
    query: '(max-width: 1200px)'
  })
  const logout = () => {
    dispatch(setUser({}))
    localStorage.clear()
    navigate("/")
  }

  const handleSetBasic = () => {    
    setCourseData(courseData1)
    setActiveBtn("beginner")
  }
  const handleSetMonthly = () => {    
    setCourseData(courseDataMonthly)
    setActiveBtn("monthly")
  }
  const handleSetYearly = () => {    
    setCourseData(courseDataYearly)
    setActiveBtn("yearly")
  }
  const handleMining = () => {    
    setActiveBtn("mining")
    setCourseData(cryptoMiningData)
  }
  const handleMemeber = () => {    
    setActiveBtn("member")
    setCourseData(youtubeMember)
  }
  const handleSetAdvance = (type) => {
    setActiveBtn("advance")
    if(type == "scd") {
      setCourseData(courseDataAdvanceSCD)
    }else{
      setCourseData(courseDataAdvanceCBD)
    }
  }

  const handleCopy = (text) => {
    copy(text)
    setCopyText("Copied")
    setTimeout(() => {
      setCopyText("Copy to clipboard")
    }, 3000);
  }
  
  useEffect(() => {
    setCourseData(courseDataMonthly)
  }, [])
  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    if (isTab) {    
      if(!expanded) {
        let toggler = document.getElementById('navbar-toggler');
        toggler.click()
      }
    }

  }, [expanded])

  console.log(location.pathname);
  

  return (
    <div className={location.pathname != "/" ?"navbar-area pr11" :"navbar-area "} >
    <div className="">
      <nav className="navbar navbar-expand-xl navbar-light">
        <div className="container-fluid">
          <NavLink to={"/"} className="navbar-brand" href="#">
            <img src={logo} alt="" />
          </NavLink>
          <button className="navbar-toggler" id='navbar-toggler' onClick={() => setExpanded(true)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" onClick={() => setExpanded(false)} to="/"> Home </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={handleShowBroadcast} > Broadcast Channels </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={handleShowYearly} > Join Yearly </a>
              </li>
              {userStore?.users?.user?._id &&
              <li className="nav-item">
                <NavLink className="nav-link" onClick={() => setExpanded(false)} to="/portfolio"> Portfolio </NavLink>
              </li>
              }
              <li className="nav-item">
                <a className="nav-link" onClick={handleShow} > Courses </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" onClick={() => setExpanded(false)} href="/#services-courses"> Courses </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" onClick={() => setExpanded(false)} href="/#about"> About </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => setExpanded(false)} href="/#testimonial"> Testimonial </a>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" onClick={() => setExpanded(false)} to="/survey"> Survey </NavLink>
              </li> */}
              {userStore?.users?.user?.wallet &&
              <li className="nav-item">
                <NavLink className="nav-link" onClick={() => setExpanded(false)} to="/post"> Post </NavLink>
              </li>
              }
              <li className="nav-item">
                {userStore?.users?.user?.name ?
                    <div className="logoutDrop">
                      <button className='default-btn name '>
                        {userStore?.users?.user?.name.length >12 ?userStore?.users?.user?.name?.slice(0,12)+" ..." : userStore?.users?.user?.name}
                      </button>
                      <div className="logout" onClick={logout}>Logout</div>
                    </div>
                :
                <>
                <div className="d-flex">
                  <NavLink className="nav-link me-0" to="/signup"> 
                      <button className='default-btn' onClick={() => setExpanded(false)}>Register</button>
                  </NavLink>
                  <NavLink className="nav-link" to="/login"> 
                      <button className='default-btn' onClick={() => setExpanded(false)}>Login</button>
                  </NavLink>
                </div>
                </>
                }
              </li>
            
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <Modal show={show} onHide={handleClose} centered className='courseModal'>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
          <i className="fa-solid fa-xmark" />
          </div>
          <div className="couseModal">
            <div className="mainBtn">
              {/* <div className={activeBtn == "beginner" ?'groupBtn ':'groupBtn active'} onClick={handleSetBasic}>For Beginner's</div> */}
              <div className={activeBtn == "monthly" ?'groupBtn ':'groupBtn active'} onClick={handleSetMonthly}>For Monthly</div>
              <div className={activeBtn == "yearly" ?'groupBtn ':'groupBtn active'} onClick={handleSetYearly}>For Yearly</div>
              <div className={activeBtn == "advance" ?'groupBtn':'groupBtn active'}  onClick={handleSetAdvance}>For Advanced</div>
              <div className={activeBtn == "mining" ?'groupBtn':'groupBtn active'}  onClick={handleMining}>Crypto Mining</div>
              {/* <div className={activeBtn == "member" ?'groupBtn':'groupBtn active'}  onClick={handleMemeber}>Youtube Members Learning Series</div> */}
            </div>
            {activeBtn == "advance" && 
              <div className="advanceTypeMain mainBtn mt-2">
                <div className={courseData[0]?.title == "Lecture 1"? 'groupBtn active':'groupBtn '}  onClick={()=>handleSetAdvance("cbd")}>CUSTOM BLOCKCHAIN DEVELOPMENT</div>
                <div className={courseData[0]?.title == "Lecture 1"? 'groupBtn':'groupBtn active'}  onClick={()=>handleSetAdvance("scd")}>SMART CONTRACT DEVELOPMENT</div>
              </div>
            }
            <div className="linkM">
              {/* <a href={val?.link} target='_blank' className="link" key={key}>{val?.title}</a> */}
              {courseData.map((val,key)=> (
                <div className="accordion" id="accordionExample">
                  <div className="">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="link" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${key}`} aria-expanded="true" aria-controls={`collapseOne${key}`}>
                       {val?.title}
                      </button>
                    </h2>
                    {val?.link.split(",").length > 1 ?
                    <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        {val?.link.split(",").map((item,key1)=>(
                          <a className='mb-1' href={item} key1={key1} target='_blank'>{item}</a>
                        ))}
                      </div>
                    </div>
                    : 
                    <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <a href={val?.link} target='_blank'>{val?.link}</a>
                      </div>
                    </div>
                    }
                  </div>
                  </div>
              ))}

            
            </div>
            {/* <div className="videoMain">
              {courseData.map((val,key)=> (
              <div className="video" key={key}>
                {val?.video}
              </div>
              ))}
            </div> */}
            {/* {courseData[0]?.video &&
            <div className='text-center'>
              <a className='default-btn' href="https://www.youtube.com/playlist?list=PLeYUkJPHvOtuiaCtvKTA5cKPqUPwYphLC" target="_blank" rel="noopener noreferrer">Playlist</a>
            </div>
            } */}
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal show={showYearly} onHide={handleCloseYearly} centered className='videoM'>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseYearly}>
          <i className="fa-solid fa-xmark" />
          </div>

          {/* <h2 className="title color-dark mb-0">To Join Yearly Group.</h2> */}
          <h5 className="color-dark text-center mb-4">Now you can pay in crypto.</h5>

            <div className="homeVideoM mb-3">
                <iframe className='w-100' width="560" height="315" src="https://www.youtube.com/embed/cZheCh813xE?si=8PfVPJqiepBr6UQY&autoplay=1&mute=1" 
                title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen>
                </iframe>
                {/* <video  className='w-100' controls>
                  <source src={homeVideo} type="video/webm" />
                  <source src={homeVideo} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
              </video> */}
            </div>

          <p className='teather'>TETHER- USDT</p>
          <p className='teather'>NETWORK- TRC20 ADDRESS:</p>
          <p className='teather'>TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz 
            <span className='copyToClip' onClick={()=>handleCopy("TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz")}>
              <i className="fa-regular fa-copy ms-2 cursor-pointer"></i>
              <span>{copyText}</span>
            </span> 
          </p>
          <p className='teather mb-3'>PAYMENT: <span >1250$</span></p>

          <div className="noteTitle">Note:</div>
          {/* <div className="closed">Membership is closed! </div>
          <div className="closed">Visit again </div> */}
          {/* <div className="note">
            Do not send any amount except yearly amount; If you send so, the amount will not be refunded and this account is just for yearly $1250 worth of usdt and again it is NOT Refundable. Send your CNIC front and back picture for KYC. If you are underage send your parents or school/college id card.
          </div> */}
          <div className="note">
          Do screen recording while doing transaction and Do not send any amount except yearly amount; If you send so, the amount will not be refunded and this account is just for yearly $1250 worth of usdt and again it is NOT Refundable. Send your CNIC front and back picture for KYC. If you are underage send your parents or school/college id card.
          </div>
          {/* <div className="desc1">Once you done payment Please Send the TX id and do screen record while doing transaction then Whatsapp Message on */}
          <div className="desc1">Once you done payment Please Send transaction successful screenshot, TX id and video of transaction  then Whatsapp Message on 
          <a href="https://wa.me/+971585036929">+971585036929 </a> send all details on WhatsApp no call will be entertained Office working hours.</div>
          <div className="desc1">01:00 PM to 09:00 PM</div>
          <div className="desc1">Monday to Friday Only</div>
         
        </div>
      </Modal.Body>
    </Modal>

    <Modal  size="lg" show={showBroadcast} onHide={handleCloseBroadcast} centered>
      <Modal.Body>
        <div className="otpModalMain broadcastModal">
          <div className="closeIcon" onClick={handleCloseBroadcast}>
          <i className="fa-solid fa-xmark" />
          </div>
          
          <div className="broadCastIco"><img src={broadCastIco} alt="" /></div>
          <h3 className="broadTitle mb-0">Join our broadcast channels for exclusive crypto and stock insights, and track Waqar Zaka's investments.</h3>
          <div className="broadBtnMain">
            <a className='themeBtn' href="https://www.instagram.com/channel/AbbEEsvUoFTOHaPf/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-instagram"></i>
              Join Insta Broadcast
            </a>
            <a className='themeBtn' href="https://m.me/j/Abat_s17duf8Dm-5/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook"></i>
              Join FB Broadcast
            </a>
          </div> 
         
        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}
const courseData1 = [
  {
    title:"Post ko end tak see more per click kar k achi tarah samja lena hai.",
    link: "https://facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044597862692645/",
  },
  {
    title:"Roz sirf 30 minute dimagh ki yeh exercises karo aur smart trader ban jao simple aur effective!",
    link: "https://www.facebook.com/share/p/1GRC16SYCG/" ,
  },
  {
    title:"Dark pool Kia hai? Paisa banane k Liya",
    link: "https://www.facebook.com/share/p/1BBEoqbJQv/" ,
  },
  {
    title:"How to build Muscle Memory? Book , Thinking  Fast and Slow Guide",
    link: "https://www.facebook.com/share/p/15nWN7asMN/" ,
  },
  {
    title:"Halal Haram Future Trading Ultimate Guide.",
    link: "https://www.facebook.com/share/p/cg9zvwrmL8nXvnvt/" ,
  },
  {
    title:"Sub sey important yeh Nahie samja tu scene kharab hai ",
    link: "https://www.facebook.com/share/p/1FGo7EZDqg/" ,
  },
  {
    title:"Emotions k Sath Trading Karna Neuro economics ki science by Waqar Zaka ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2046208865864878/" ,
  },
  {
    title:"Coin ki selection karna 15 minutes mei profit karney k Liya ? Possible ? Let's discover.",
    link: "https://www.facebook.com/share/p/15Qfbrqg8S/?" ,
  },
  {
    title:"Demo Trade K Liye Checklist and method ",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044666909352407/" ,
  },
  {
    title:"Future trading k Liya Waqar Zaka kesay Coin select karta hai ? ",
    link: "https://www.facebook.com/share/p/14UxY1oWFB/" ,
  },
  {
    title:"Profitable Coin Kaise Search Karein? Easy steps for new comer",
    link: "https://www.facebook.com/share/p/19Re533QT1/?" ,
  },
  {
    title:"Daily profit banana hai tu yeh exercise start karlo",
    link: "https://www.facebook.com/share/p/1B5XmH14M5/" ,
  },
  {
    title:"Leverages traders ki exercise",
    link: "https://www.facebook.com/share/p/1Ewn7nrAoV/" ,
  },
  {
    title:"How waqarzaka make profit using RSI?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044456956040069/" ,
  },
  {
    title:"What is a Exchange?",
    link: "https://www.facebook.com/waqarzaka/videos/597929235762486/?idorvanity=932060957279680" ,
  },
  {
    title:"What is wallet?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/permalink/1449362605549510/?app=fbl" ,
  },
  {
    title:"How to register at Bitget?",
    link: "https://fb.watch/vT1qIAl-Ha/" ,
  },
  {
    title:"How to transfer funds to Bitget?",
    link: "https://fb.watch/vT1vA0xc1g/" ,
  },
  {
    title:"How to do Spot,Futures and Demo trading?",
    link: "https://fb.watch/vT1wnaYy7V/" ,
  },
  {
    title:"How to calculate liquidation price?",
    link: "https://fb.watch/vT1xpbYFaP/" ,
  },
  {
    title:"How to use Bitget Support for any issue?",
    link: "https://fb.watch/vT1yai8SmR/" ,
  },
  {
    title:"Trading View",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=3128029824168453&post=945027726650336" ,
  },
  {
    title:"Trading Basics",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=716724992735762" ,
  },
  {
    title:"Portfolio Management",
    link: "https://fb.watch/vT1CSpTYhy/" ,
  },
  {
    title:"Candlesticks and Price action",
    link: "https://fb.watch/vT1DOi7nw-/" ,
  },
  {
    title:"Future trading and practice",
    link: "https://fb.watch/vT1EupRNK5/" ,
  },
  {
    title:"Support and Resistance",
    link: "https://fb.watch/vT1FdiXwEE/" ,
  },
  {
    title:"Chart patterns",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content?filter=567843461716238&post=837996643852624,https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1460669514406902,https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=801868520831945" ,
  },
  {
    title:"RSI",
    link: "https://fb.watch/vT1JRoAeho/" ,
  },
  {
    title:"ATR",
    link: "https://fb.watch/vT1KCESt-c/" ,
  },
];

const courseDataMonthly = [
  {
    title:"Introduction",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=734334721741684&post=1441118890080260" ,
  },
  {
    title:"How to use Group?",
    link: "https://facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044597862692645/",
  },
  {
    title:"Brain excercises",
    link: "https://www.facebook.com/share/p/1GRC16SYCG/" ,
  },
  {
    title:"Build Muscle Memory",
    link: "https://www.facebook.com/share/p/15nWN7asMN/" ,
  },
  {
    title:"Halal and Haram in Crypto",
    link: "https://www.facebook.com/share/p/cg9zvwrmL8nXvnvt/" ,
  },
  {
    title:"Wallet Guide",
    link: "https://www.facebook.com/share/p/1FGo7EZDqg/" ,
  },
  {
    title:"How to Manage Emotions?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2046208865864878/" ,
  },
  {
    title:"Coin selection Tips",
    link: "https://www.facebook.com/share/p/15Qfbrqg8S/?" ,
  },
  {
    title:"Demo Trading Tips",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044666909352407/" ,
  },
  {
    title:"Future trading k Liya Waqar Zaka kesay Coin select karta hai ? ",
    link: "https://www.facebook.com/share/p/14UxY1oWFB/" ,
  },
  {
    title:"How to find profitable coins?",
    link: "https://www.facebook.com/share/p/19Re533QT1/?" ,
  },
  {
    title:"Daily profit banana hai tu yeh exercise start karlo",
    link: "https://www.facebook.com/share/p/1B5XmH14M5/" ,
  },
  {
    title:"Leverages traders ki exercise",
    link: "https://www.facebook.com/share/p/1Ewn7nrAoV/" ,
  },
  {
    title:"How waqarzaka make profit using RSI?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/posts/2044456956040069/" ,
  },
  {
    title:"What is a Exchange?",
    link: "https://www.facebook.com/waqarzaka/videos/597929235762486/?idorvanity=932060957279680" ,
  },
  {
    title:"What is wallet?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/permalink/1449362605549510/?app=fbl" ,
  },
  {
    title:"How to register your account at bitget?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=1686651868382407" ,
  },
  {
    title:"How to transfer funds from exchange?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=395692709362344" ,
  },
  {
    title:"How to trade at bitget?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=367452355593520" ,
  },
  {
    title:"How to use bitget support?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=769690621017060&post=3211839742412325" ,
  },
  {
    title:"Types of trading,markets and what is DCA",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=716724992735762" ,
  },
  {
    title:"Portfolio Management and Risk management",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=598043685362237" ,
  },
  {
    title:"Candlesticks and price action trading",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1906103036792079" ,
  },
  {
    title:"Long & Short positions",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=414550740489852" ,
  },
  {
    title:"Support & Resistance",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=764285741279770" ,
  },
  {
    title:"Chart patterns 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=837996643852624",
  },
  {
    title:"Chart patterns 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1460669514406902",
  },
  {
    title:"Chart patterns 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1119127712287311",
  },
  {
    title:"Chart patterns 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=801868520831945",
  },
  {
    title:"Chart patterns 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=743213373427425",
  },
  {
    title:"Chart patterns 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=749119553035469",
  },
  {
    title:"Chart patterns 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=3178049332434646",
  },
  {
    title:"RSI/ STOCH RSI",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=1190207468371870",
  },
  {
    title:"ATR",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=567843461716238&post=733427481075059",
  },
  {
    title:"OCO/Trailing stop",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=841089307078337",
  },
  {
    title:"Money Flow",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=645810237026213",
  },
  {
    title:"How to calculate PNL?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=432794568841299&post=5567454706673062",
  },
  {
    title:"Dial Indicator",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=219331154286176",
  },
  {
    title:"MACD",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=757769235858053",
  },
  {
    title:"Golden Cross",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=230459739544849",
  },
  {
    title:"Death Cross",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=1300390880822621",
  },
  {
    title:"EMA",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=183797814390264&post=1149499779050468",
  },
  {
    title:"Dark pool Kia hai? Paisa banane k Liya",
    link: "https://www.facebook.com/share/p/1BBEoqbJQv/" ,
  },
  {
    title:"How to create a Crypto Short signal?",
    link: "https://www.facebook.com/share/p/1AwguMfRxR/" ,
  },
]

const courseDataYearly = [
  {
    title:"Basics ",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1467504803695087",
  },
  {
    title:"Types of trading,markets and what is DCA",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=729378421681237",
  },
  {
    title:"Portfolio Management and Risk management",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1759634054372297",
  },
  {
    title:"Candlesticks and price action trading",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=561446165621652",
  },
  {
    title:"Long & Short positions",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=462053649097982",
  },
  {
    title:"Support & Resistance",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=749580986291437",
  },
  {
    title:"Chart patterns 1",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=578381407106697",
  },
  {
    title:"Chart patterns 2",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1063577657620135",
  },
  {
    title:"Chart patterns 3",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=3230924973831183",
  },
  {
    title:"Chart patterns 4",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=1276850623070770",
  },
  {
    title:"Chart patterns 5",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=507212134549476",
  },
  {
    title:"Chart patterns 6",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=2872478139715089",
  },
  {
    title:"Chart patterns 7",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=5868009633228803",
  },
  {
    title:"RSI/ STOCH RSI",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=664525205393417",
  },
  {
    title:"ATR",
    link: "https://www.facebook.com/groups/263892551821017/learning_content/?filter=831034011210822&post=402575111762849",
  },
  {
    title:"How to create a Crypto Short signal?",
    link: "https://www.facebook.com/share/p/1AgoHFWKx2/" ,
  },
 
]

const courseDataAdvanceCBD = [

  {
    title:"Introduction",
    link: "https://fb.watch/wtceK5Z9nW/,https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=493894868561317",
  },
  {
    title:"Lesson 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=514545526633859" ,
  },
  {
    title:"Lesson 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=788318128716246" ,
  },
  {
    title:"Lesson 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=363082595152415" ,
  },
  {
    title:"Lesson 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=320441303070089" ,
  },
  {
    title:"Lesson 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=337268464618120" ,
  },
  {
    title:"Lesson 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=716492632770167" ,
  },
  {
    title:"Lesson 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=550667219400280" ,
  },
  {
    title:"Lesson 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=189211189893643" ,
  },
  {
    title:"Lesson 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=1169626240213569" ,
  },
  {
    title:"Lesson 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=155714276730528" ,
  },
  {
    title:"Lesson 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=554780999164169" ,
  },
  {
    title:"Lesson 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=694810251499704" ,
  },
  {
    title:"Lesson 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=327172969167414" ,
  },
  {
    title:"Lesson 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=2673190429493097" ,
  },
  {
    title:"Lesson 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=240054857476009&post=394392149006560" ,
  },
 
]
const courseDataAdvanceSCD = [
  {
    title:"Lecture 1",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=743410550161480" ,
  },
  {
    title:"Lecture 2",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=794224938234333" ,
  },
  {
    title:"Lecture 3",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=433108415025113" ,
  },
  {
    title:"Lecture 4",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=396392372305135" ,
  },
  {
    title:"Lecture 5",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=4817595151679395" ,
  },
  {
    title:"Lecture 6",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3265395383741687" ,
  },
  {
    title:"Lecture 7",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=716492632770167" ,
  },
  {
    title:"Lecture 8",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=464322751722803" ,
  },
  {
    title:"Lecture 9",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=348249514147797" ,
  },
  {
    title:"Lecture 10",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1106872563369520" ,
  },
  {
    title:"Lecture 11",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=5755545804479266" ,
  },
  {
    title:"Lecture 12",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=752602629330969" ,
  },
  {
    title:"Lecture 13",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=783787625986151" ,
  },
  {
    title:"Lecture 14",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=857612015374582" ,
  },
  {
    title:"Lecture 15",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=490201485888900" ,
  },
  {
    title:"Lecture 16",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1499694730480133" ,
  },
  {
    title:"Lecture 17",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1832740150413645" ,
  },
  {
    title:"Lecture 18",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1139737323331176" ,
  },
  {
    title:"Lecture 19",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3203143013281189" ,
  },
  {
    title:"Lecture 20",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=810454306884990" ,
  },
  {
    title:"Lecture 21",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=530115632438312" ,
  },
  {
    title:"Lecture 22",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=53011563243831" ,
  },
  {
    title:"Lecture 23",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=787991545593965" ,
  },
  {
    title:"Lecture 24",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=572647994433260" ,
  },
  {
    title:"Lecture 25",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=585964776713339" ,
  },
  {
    title:"Lecture 26",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=806682277445327" ,
  },
  {
    title:"Lecture 27",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=900628971122671" ,
  },
  {
    title:"Lecture 28",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=175575491882015" ,
  },
  {
    title:"Lecture 29",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=981868393196955" ,
  },
  {
    title:"Lecture 30",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3370437199864964" ,
  },
  {
    title:"Lecture 31",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=583652507048379" ,
  },
  {
    title:"Lecture 32",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=268892515625561" ,
  },
  {
    title:"Lecture 33",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1343345429729305" ,
  },
  {
    title:"Lecture 34",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=2317617701755561" ,
  },
  {
    title:"Lecture 35",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1443228446429223" ,
  },
  {
    title:"Lecture 36",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=266859929292705" ,
  },
  {
    title:"Lecture 37",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=3385423201721394" ,
  },
  {
    title:"Lecture 38",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=707796594506516" ,
  },
  {
    title:"Lecture 39",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=620885926818049" ,
  },
  {
    title:"Lecture 40",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=669236778422838" ,
  },
  {
    title:"Lecture 41",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=1344582103145382" ,
  },
  {
    title:"Lecture 42",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=978294940061909" ,
  },
  {
    title:"Lecture 43",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=314356518036549" ,
  },
  {
    title:"Lecture 44",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/learning_content/?filter=391972289555635&post=545985417750002" ,
  },
]

const cryptoMiningData = [
  {
    title:"Crypto mining A to Z guide.",
    link: "https://www.facebook.com/share/p/1L2ejgsnUc/",
  },
  {
    title:"Using Hashrate to find buy sell signal on bitcoin Buy sell ka Easy Indicator using Hashrate.",
    link: "https://www.facebook.com/share/p/14dWQar2CX/",
  },
  {
    title:"Crypto Mining: How Much Can You Earn with Minimum Investment?",
    link: "https://www.facebook.com/share/p/19Nh2z5xEa/",
  },
  {
    title:"Rs. 50 Lacs Monthly earning k Liya crypto mining ki details.",
    link: "https://www.facebook.com/share/p/19kAXbsiA9/",
  },
  {
    title:"For  US members , Crypto mining details to earn 20K USD per month.",
    link: "https://www.facebook.com/share/p/1Av7oQGt58/",
  },
  {
    title:"1000 Dollars per Month without trading ? GPU VS HNT mining.",
    link: "https://www.facebook.com/share/p/18Axcn6Z1j/",
  },
  
]

const youtubeMember = [
  {
    title:"Learning Series",
    link: "https://www.youtube.com/playlist?list=PLeYUkJPHvOtuiaCtvKTA5cKPqUPwYphLC",
  },

  
]



