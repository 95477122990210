import React,{useEffect, useState} from "react";

// import { loginUser, loginUserReq } from "../../../redux/ActionCreators";
import { useDispatch, useSelector } from 'react-redux';
import userAction from "../../../redux/users/action";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Copy from "../../../images/Copy.svg"
import chainIco from "../../../images/Dice.svg"
import { Connector, useConnect, useAccount, useDisconnect } from 'wagmi'
import ethIco from "../../../images/ethIco.png";

export function NavbarPorfoilio({ethData,wallet}) {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
  const userStore = useSelector((state) => state.userReducer);
  const {setUser} = userAction;
  // const authedUser = useSelector(s => s.authedUser.authedUser);
  const logout = () => {
    dispatch(setUser({}))
    localStorage.clear()
    if(address) {
      disconnect()
    }
    navigate("/")
  }
  

  useEffect(() => {
    let token=localStorage.getItem("token")
      if(token){
        axios.defaults.headers.common["Authorization"]=token;
      }else{
        logout()
      }
  }, [])  

    return(
        <>
             {/* Topbar */}
             <nav className="navbar portfolioNav navbar-expand navbar-light topbar static-top">
                {/* Sidebar Toggle (Topbar) */}
                {/* <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars" />
                </button> */}
              
                {/* Topbar Navbar */}
                    {/* <div className="walletBtn ms-auto px-3">
                    <button className="btn" onClick={logout}>
                        logout
                    </button>
                    </div> */}
                     {/* Topbar Navbar */}
                     <h3 className="head">Dashboard</h3>
                     <ul className="navbar-nav ml-auto">
                        <li>
                            <div className="chainM">
                                <div className="chain me-3">
                                    <div className="ico"><img src={ethIco} alt="" /></div>
                                    {ethData?.ETH?.balance ? ethData?.ETH?.balance.toFixed(5) : 0}
                                </div>
                                <div className="chain">
                                    <div className="ico"><img src={chainIco} alt="" /></div>
                                    {wallet ?
                                    wallet.slice(0,5)+"..."+ wallet.slice(wallet.length-4,wallet.length)
                                    :
                                    "Ethereum"
                                    }
                                </div>
                                
                            </div>
                        </li>
                        <li className="nav-item dropdown no-arrow">
                            <a className="addressBtn dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {/* <img className="img-profile rounded-circle" src={profile} /> */}

                                <div className="profile-div ml-2">
                                <span className="mr-2 small profile-name">{userStore?.users?.user?.name ?userStore?.users?.user?.name:"User Name"} </span>
                                {/* <span className="status">Active</span> */}
                                </div>
                                <div>
                                {/* <img src={dropdownIcon} className="w-9 ml-1 mb-2" alt="dropdown icon" /> */}
                                </div>

                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                {/* <a className="dropdown-item" href="#">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                                Profile
                                </a> */}
                                {/* <div className="dropdown-divider" /> */}
                                <a className="dropdown-item" onClick={logout}>
                                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                  Logout
                                </a>
                            </div>
                        </li>
                    </ul>
             
              </nav>
              {/* End of Topbar */}

        </>
    )
    
}
