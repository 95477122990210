import React,{useEffect} from "react";
import { BrowserRouter, BrowserRouter as Router,Route,Switch, useLocation, useParams } from "react-router-dom";
import ReactDOM from 'react-dom';


import { NavLink,Link } from "react-router-dom";
import $ from 'jquery';

// import profile from "../../../images/profile.jpg";
import logo from "../../../images/logo.png";
import overviewICo from "../../../images/overviewICo.png";
import ethIco from "../../../images/ethIco.png";
import transactionIco from "../../../images/transactionIco.png";


function SidebarPortfolio({allwallets,deleteWallet}) {
  const location = useLocation()
    const {activeWallet} = useParams()    
    return(
        <>

             {/* Sidebar */}
          <ul className="navbar-nav sidebar sidebar-dark accordion zi-1 " id="accordionSidebar">
            {/* Sidebar - Brand */}
              <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-0" to="/">
                <div className="sidebar-brand-text mx-3">
                  <img src={logo} className="w-100" alt="waqarzaka.net" />
                </div>
                <div className="mini">
                  <img src={logo} className="w-100" alt="waqarzaka.net" />
                </div>
              </Link>
            <div className="sideInner">
            
              {/* sidebar links */}
              <li className="nav-item my-1 ">
                  <NavLink className="nav-link sidebar-Link fw-500" to="/portfolio/tx">
                      <img className="" src={overviewICo} alt="" />
                      <span className="">Overview</span>
                  </NavLink>
              </li>
              <li className="nav-item my-1 ">
                <a className="nav-link">
                  <span className="ms -0">My Web3 Porfolio ({allwallets.length || 1})</span>
                </a>
              </li>

              {allwallets.map((item,key)=>(
                <li className="nav-item my-1 pr" key={key}>
                    <NavLink className="nav-link sidebar-Link fw-500"  to={`/portfolio/dashboard/${item?.wallet}`}>
                        <img className="" src={ethIco} alt="" />
                        <span className="">{item?.wallet ? item?.wallet.slice(0,5)+"..."+ item?.wallet.slice(item?.wallet.length-4,item?.wallet.length)  : "Wallet 1" }</span>
                        <i onClick={()=>deleteWallet(item?.wallet,item?.userID)} className="fa-regular fa-trash-can deleteIco"></i>
                    </NavLink>
                </li>
              ))}

              {/* <li className="nav-item my-1 ">
                  <NavLink className="nav-link sidebar-Link fw-500" to="/portfolio/tx">
                      <img className="" src={transactionIco} alt="" />
                      <span className="">Transaction</span>
                  </NavLink>
              </li> */}
              {allwallets.length == 0 && 
              <li className="nav-item my-1">
                  <NavLink className={
                    location.pathname.includes('/portfolio/dashboard') ?
                    "nav-link sidebar-Link fw-500 active"
                    :
                    "nav-link sidebar-Link fw-500"
                  }  to="/portfolio/dashboard/1">
                      <img className="" src={transactionIco} alt="" />
                      <span className="fs-md-13">Web3 Wallet</span>
                  </NavLink>
              </li>
              }
              {/* <li className="nav-item my-1">
                <NavLink className="nav-link sidebar-Link fw-500" to="/admin/users">
                  <span className="">Users</span></NavLink>
              </li> */}
      
            
            
            
              {/* Nav Item - Pages Collapse Menu */}
              {/* <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  <i className="fas fa-fw fa-cog" />
                  <span>Components</span>
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Custom Components:</h6>
                    <a className="collapse-item" href="buttons.html">Buttons</a>
                    <a className="collapse-item" href="cards.html">Cards</a>
                  </div>
                </div>
              </li> */}
            </div>
           
       
          </ul>
          {/* End of Sidebar */}

        </>
    )
    
}
export default SidebarPortfolio