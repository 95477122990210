import React, { useEffect, useState } from 'react'
import { Loader } from './loader'
import api from '../../api'
import moment from 'moment'

export const DataInfo = () => {
  const [loader,setLoader]=useState(false)
  const [data, setData]=useState([])

  const getUsers = async()=>{
    try {
      setLoader(true)
      const response = await api.getDataInfo()
      setData(response)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  useEffect(() => {
    getUsers()
  }, [])
  

  return (
    <>
        {/* Begin Page Content */}
        <div className="container-fluid">
            <div className="row">
                <AnalyticsBox icon={<i className="fa-solid fa-users"></i>} title="Total Users"  amount={data?.userCount} />
                <AnalyticsBox icon={<i className="fa-solid fa-wallet"></i>} title="Total Wallets" amount={data?.walletCount} />
                <AnalyticsBox icon={<i className="fa-solid fa-paste"></i>} title="Total Post"  amount={data?.PostCount} />

            </div>
            <div className="row">
                <div className="col">
                    <h3>Recent Users</h3>
                    <Table data={data?.recentUser ? data?.recentUser : [] } />

                </div>
            </div>
                
              
        </div>
        {/* /.container-fluid */}
        {loader && 
        <Loader />
        }
    </>
  )
}

function AnalyticsBox(props) {
    return(
      <div className="col-xl-4 col-md-6 mb-4">
      <div className="card h-100 py-2 border-transparent br-10 bg-gray1">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col">
              {/* <img className="analytic-box-img" src={props.icon} alt="" /> */}
              <div className="analytic-box-img">{props.icon}</div> 
            </div>
            <div className="col-auto mr-2">
              <div className="text-xs font-weight-bold  mb-1">
                <span className="analytics-box-title">{props.title}</span> 
              </div>
              <div className="h3 mb-0 font-weight-bold text-gray-800">{props.amount}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}


const Table=({data})=>{
    // const authedUser = useSelector(s => s.authedUser.authedUser);


    const formatDate = (date) => {
      let dateTemp = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return dateTemp;
    }

    return(
      <>
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">Joined At</th>
                  <th scope="col" className="">Name</th>
                  <th scope="col" className="">Email</th>
                  <th scope="col" className="">Phone</th>
                  <th scope="col" className="">Wallet</th>
                  <th scope="col" className="">country</th>
                  <th scope="col" className="">DOB</th>
                  <th scope="col" className="">Gender</th>
                  <th scope="col" className=""><div className='w-max-c'>Email verified</div></th>
                </tr>
              </thead>
              <tbody>
              {data.map((item,key)=>(
                <tr key={key}>
                  <td >
                    <div className="table-text-dark w-max-c db">{ formatDate(item?.createdAt) }</div>
                  </td>
                  <td scope="row" className="d-flex">
                    <div className="table-text-dark w-max-c db">{item?.name}</div>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.email}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.phone ? item?.phone :"-"}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.wallet ?item?.wallet :"-"}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.country ?item?.country :"-"}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.dob}</span>
                  </td>
                  <td>
                    <span className="table-text-dark w-max-c db">{item?.gender}</span>
                  </td>
                  <td>
                    {item?.emailVerified ?
                    <span className="table-text-dark w-max-c db">verified</span>
                    :
                    <span className="table-text-dark w-max-c db">not verified</span>
                    }
                  </td>
                </tr>
              ))}
            
              </tbody>
            </table>
          </div>
       
      </div>

    </div>

    
      </>
    )
  }
