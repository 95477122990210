import React, { useEffect, useState } from 'react'
import portfolioImg from "../../images/portfolioImg.png"
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import axios from 'axios';
import userAction from "../../redux/users/action";
import dashboardImg from "../../images/dashboardImg.png"

export const Portfolio = () => {

    const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const [wallets,setWallets]=useState('');
  const [tx,setTx]=useState('');

    const getExist = async () => {
      // e.preventDefault();
      // setLoader(true);
      try {
       await checkUserSession()
  
        const getResponse = await api.checkPortfolioExist(userStore?.users?.user?._id);
        // console.log(getResponse);
        
        if(getResponse?.success) {
              // navigate(`/portfolio/dashboard/${getResponse?.res[0]?.wallet || 1}`);
              setWallets(getResponse?.wallet)
              setTx(getResponse?.portfolio)
          }else{
            alert.error("Something went wrong")
            // setLoader(false);
        }
      
      } catch (e) {
        alert.show(e.message);
        // alert.show("Invalid Credentials or Account not verified");
        // setLoader(false);
      }
    };

    const checkUserSession = async() => {
      try {
        let token=localStorage.getItem("token")
        // console.log(token);
        if(token){
          let payload = {token:token}
          const registerationResponse = await api.verifySession(payload);
          // console.log(registerationResponse);
          if(registerationResponse?.token){
            axios.defaults.headers.common["Authorization"]=registerationResponse?.token
          }else{
            dispatch(setUser({}));
            localStorage.removeItem('token')
            navigate('/')
          }
        }
        
      } catch (error) {
        console.log(error);
        
      }
    }

    useEffect(() => {
      if(userStore?.users?.user?._id) {
        getExist()
      }
    }, [userStore?.users])
    

  return (
    <div className='portfolioSec'>
         <div className="container">
            <div className="portfolioM mb-30 text-center">
                <div className="section-title">
                  <h1>Unlock Your Decentralized Trading Dashboard</h1>
                  <p className='mb-4'> Your gateway to the decentralized economy </p>
                </div>
                {tx > 0 ? 
                <Link to="/portfolio/tx" className="default-btn">Go to your portfolio</Link>
                :
                wallets > 0 ?
                <Link to="/portfolio/dashboard/1" className="default-btn">Go to your portfolio</Link>
                :
                <Link to="/portfolio/connect" className="default-btn">Go to your portfolio</Link>
                }
              <div className="dashboardImg">
                <img className='img-fluid' src={dashboardImg} alt="" />
              </div>
            </div>
        </div>
    </div>
  )
}
